import React from "react"
import Layout from "../../Layout"
import Meta from "../../components/Meta"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import Spacer from "../../components/Spacer"
import StaticHeadline from "../../components/static/StaticHeadline"
import StaticParagraph from "../../components/static/StaticParagraph"
import StaticContainer from "../../components/static/StaticContainer"
import StaticImage from "../../components/static/StaticImage"

import StaticOl from "../../components/static/StaticOl"

import usePagesImages from "../../hooks/usePagesImages"

export default function BenzinpreiseEntwicklung({ data }) {
  const fluid = usePagesImages("benzinpreise-entwicklung.jpg")

  return (
    <Layout>
      <Meta
        title="Benzinpreise – Entwicklung und Geschichte"
        description="Wie verlief die Benzinpreisentwicklung in den letzten Jahrzehnten und warum ist der Spritpreis nach einem Jahr Corona wieder so hoch? Jetzt auf Tankchecker lesen."
        image={fluid.src}
      />
      <Header />
      <main>
        <StaticContainer maxWidth="lg">
          <Spacer />
          <StaticHeadline variant="h1" align="center" gutterBottom>
            Benzinpreise: Entwicklung und Geschichte
          </StaticHeadline>
          <StaticParagraph gutterBottom>
            Schaut man sich die Entwicklung der Benzinpreise seit 1950 an, so
            erkennt man, dass sich seit dem Beginn der 70er-Jahre die
            durchschnittlichen Spritpreise ungefähr vervierfacht haben. Die
            untenstehende Grafik verdeutlicht sehr anschaulich, dass man für
            einen Liter Superbenzin noch vor vier Jahrzehnten einen Bruchteil
            dessen bezahlen musste, was man heute dafür an der Tankstelle
            ausgeben muss.
          </StaticParagraph>
          <StaticImage
            fluid={fluid}
            alt="Benzinpreise – Entwicklung und Geschichte"
            copyright="Bildnachweis: Statista"
          />
          <StaticParagraph gutterBottom>
            Deutlich zu erkennen sind diverse Preisanstiege und -spitzen, so
            etwa zwischen 1981 bis 1984, dann noch einmal ein sehr deutlicher
            Anstieg zwischen 1998 und 2000 sowie zwischen 2009 und 2012.
          </StaticParagraph>
          <StaticParagraph gutterBottom>
            Die Entwicklung der Benzinpreise hat immer etwas mit
            wirtschaftlichen und politischen Ereignissen weltweit – und
            besonders in den erdölexportierenden Ländern – zu tun. Deutschland
            selbst hat nur sehr geringe Erdölvorkommen. So sind wir abhängig von
            erdölfördernden und -produzierenden Staaten wie Russland, Norwegen,
            Libyen und Großbritannien, die unsere Haupthandelspartner für Rohöl
            sind. Weitere Bezugsquellen sind unter anderem die USA, Irak,
            Kasachstan und Nigeria.
          </StaticParagraph>
          <StaticHeadline variant="h2" gutterBottom>
            Welche Faktoren beeinflussen die Benzinpreise in Deutschland?
          </StaticHeadline>
          <StaticParagraph gutterBottom>
            Generell kann festgestellt werden, dass deutliche Schwankungen in
            den Kraftstoffpreisen immer im Zusammenhang stehen mit Angebot und
            Nachfrage, vor allem aber auch durch geopolitische Faktoren wie
            Umweltkatastrophen, militärische Interventionen und die Preispolitik
            der OPEC (Organisation der erdölexportierenden Länder) beeinflusst
            werden. Dem Handelsabkommen OPEC gehören insgesamt 15 Staaten an,
            darunter alle Länder in Afrika (bis auf Ecuador und Venezuela) und
            des nahen Ostens.
          </StaticParagraph>
          <StaticParagraph gutterBottom>
            So kann man sehen, dass während und nach den zwei Ölkrisen die
            Preise zwischen 1973 und 1975 deutlich anstiegen und dann noch
            einmal Anfang der 80er Jahre, als der 1. Golfkrieg ausbrach.
          </StaticParagraph>
          <StaticParagraph gutterBottom>
            2008 erreichte der Rohölpreis einen neuen Höchststand.
            Beeinflussende Faktoren für die extreme Steigerung der Benzinpreise
            der davorliegenden Jahre sind die stark gestiegenen Nachfragen von
            Ländern wie China und Indien sowie Krisen wie der Irak-Krieg, der
            Afghanistan-Krieg und die Kriege im Kongo.
          </StaticParagraph>
          <StaticParagraph gutterBottom>
            Während der globalen Finanz- und Wirtschaftskrise 2008/2009 wurde
            eine bis heute stark umstrittene Fördermethode entwickelt: das
            Fracking. Die Ölproduktion stieg sehr stark an, demzufolge sank der
            Ölpreis und in direkter Folge damit auch die Benzinpreise. 2009
            kostete der Liter Super-Benzin zeitweilig nur noch 1,25 Euro und der
            Liter Diesel sank bis auf ca. 1,10 Euro herab.
          </StaticParagraph>
          <StaticParagraph gutterBottom>
            Danach stiegen die Preise innerhalb der nächsten Jahre aber wieder
            kontinuierlich an, bis sie schließlich 2012 bisher nie gekannte
            Höchstpreise erreichten. Ein Liter Super kostete zu dem Zeitpunkt
            durchschnittlich ca. 1,64 Euro.
          </StaticParagraph>
          <StaticParagraph gutterBottom>
            Von 2012 bis 2016 sanken die Preise wieder. Die OPEC beschloss daher
            als Gegenmaßnahme im Jahr 2016, die Fördermengen an Öl deutlich zu
            kürzen, um dem weiteren Preisverfall entgegenzuwirken.
          </StaticParagraph>
          <StaticHeadline variant="h2" gutterBottom>
            Warum ist der Spritpreis von Benzin und Diesel in Deutschland so
            hoch?
          </StaticHeadline>
          <StaticParagraph gutterBottom>
            Deutschland hat im Vergleich zu anderen Ländern sehr hohe Preise.
            Ähnlich hoch sind die Preise noch in anderen EU-Ländern wie Belgien,
            Niederlande, Frankreich oder Italien. Günstiger wirds dagegen z. B.
            in Ungarn, Tschechien oder Spanien.
          </StaticParagraph>
          <StaticParagraph gutterBottom>
            Die hohen Preise begründen sich darin, dass alle diese Länder keine
            oder nicht nennenswerte Erdölproduktion aufweisen. Länder wie die
            USA oder Kanada, die sehr hohe Ölförderung und große Erdölreserven
            aufweisen, haben bedingt durch die gute Verfügbarkeit deutlich
            niedrigere Benzinpreise. In Deutschland entfallen allein mehr als
            zwei Drittel des Benzinpreises auf die Steuerabgaben wie die
            Mehrwertsteuer, die Ökosteuer, die Mineralölsteuer und ganz neu nun
            auch die CO2-Steuer. Allgemein kann man sagen: Reichere Länder haben
            höhere Preise (mit Ausnahme der USA, die als wirtschaftliches Land
            dank der eigenen Erdölförderung auch niedrigere Benzinpreise haben)
            und ärmere Länder sowie die erdölproduzierenden und
            erdölexportierenden Länder haben eher niedrigere Preise.
          </StaticParagraph>
          <StaticHeadline variant="h2" gutterBottom>
            Die aktuelle Benzinpreisentwicklung seit Corona-Beginn
          </StaticHeadline>
          <StaticParagraph gutterBottom>
            Seit Beginn der Covid-19-Pandemie Anfang 2020 sanken die
            Benzinpreise und pendelten sich irgendwo im Bereich zwischen 1,20
            Euro und 1,30 Euro ungefähr ein während des letzten Jahres. Das lag
            zum einen an der beschlossenen Mehrwertsteuer-Senkung von 19 auf 16
            Prozent, die natürlich auch Auswirkungen auf die Kraftstoffpreise an
            den Tankstellen hatte.
          </StaticParagraph>
          <StaticParagraph gutterBottom>
            Seit Beginn des Jahres 2021 steigen die Preise aber wieder deutlich
            an. Woran liegt das? Hier spielen mehrere Faktoren eine Rolle:
          </StaticParagraph>
          <StaticOl>
            <li>
              Die im Jahr 2020 beschlossene Senkung der Mehrwertsteuer um 3
              Prozent lief Ende des Jahres aus. Damit ist sie seit dem
              01.01.2021 wieder auf dem alten Mehrwertsteuer-Niveau und die
              Autofahrer zahlen wieder die üblichen Mehrwertsteuersätze auf
              Kraftstoffe.
            </li>
            <li>
              Es gibt seit Jahresbeginn eine neue Steuer, die in den Benzinpreis
              mit hineingerechnet werden muss: die CO2-Steuer. Pro verursachter
              Tonne CO2 müssen 25 Euro bezahlt werden. Daher haben sich laut dem
              ADAC die Preise bei Super E10 im Schnitt um 7,7 Cent und bei
              Dieselkraftstoff um ca. 7,6 Cent pro Liter verteuert. Du kannst
              Dir also ab jetzt schon mal den Jahreswechsel immer vormerken. Die
              CO2-Steuer wird jährlich angehoben, es ist also ab jetzt immer mit
              einem Anstieg der Benzinpreise zum Jahresbeginn zu rechnen.
            </li>
            <li>
              Der Ölpreis ist gestiegen. Gerade in den ersten Monaten der
              Corona-Pandemie kam es zu einem regelrechten Einbruch im
              Transportwesen und auch in der Tourismus-Industrie. Die Wirtschaft
              wurde quasi runtergefahren, das ließ den Ölpreis wiederum auf ein
              Rekordtief sinken. Inzwischen steigt er aber wieder, weil man an
              der Börse in Rotterdam auf ein baldiges Ende der Pandemie hofft
              und damit auf ein Ende der coronabedingten
              (Mobilitäts-)Einschränkungen. Die Hoffnung auf einen baldigen
              Wirtschaftsaufschwung lässt also die Ölpreise wieder kräftig
              steigen.
            </li>
            <li>
              Extremwetter-Phänomene lassen in den USA die Ölförderung stocken
              und damit die Preise steigen. Experten schätzen, dass ab April
              wieder mehr Öl gefördert wird und damit die Chancen auf wieder
              fallende Benzinpreise für den Verbraucher in Sicht sind.
            </li>
          </StaticOl>
          <StaticParagraph gutterBottom>
            Es lohnt sich also, gerade in den kommenden Wochen einen
            aufmerksamen Blick auf die Entwicklung der Benzinpreise in
            Deutschland zu werfen. Gute Benzinpreis-Vergleichsapps und -seiten
            helfen dabei, den Überblick zu behalten und immer den möglichst
            günstigsten Spritpreis beim nächsten Tanken zu erwischen.
          </StaticParagraph>
          <Spacer />
          <Spacer />
        </StaticContainer>
      </main>
      <Footer />
    </Layout>
  )
}
